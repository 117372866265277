import clsx from "clsx";
import type { ReactNode } from "react";
type ToolbarMenuItemProps = {
  readonly children: ReactNode;
  readonly className?: string;
};
export function ToolbarMenuItem({
  children,
  className
}: ToolbarMenuItemProps) {
  return <li className={clsx("relative flex h-full items-center justify-center", className)} data-sentry-component="ToolbarMenuItem" data-sentry-source-file="ToolbarMenuItem.component.tsx">
      {children}
    </li>;
}