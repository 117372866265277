"use client";

import type { ReactNode } from "react";
import type { CommonButtonProps } from "@/components/Button/regular-button.types";
import { RegularLinkButton } from "@/components/Button/RegularLinkButton/RegularLinkButton.component";
import { useUser } from "@/contexts/user/user.context";
type LoginButtonProps = Pick<CommonButtonProps, "variant" | "position"> & {
  readonly className?: string;
  readonly children: ReactNode;
  readonly redirectTo?: string;
};
export function LoginButton({
  className,
  children,
  redirectTo,
  variant = "primary",
  position
}: LoginButtonProps) {
  const {
    urls: {
      createLoginUrl,
      loginAndReturnUrl
    }
  } = useUser();
  return <RegularLinkButton className={className} href={redirectTo !== undefined ? createLoginUrl(redirectTo) : loginAndReturnUrl} openInNewTab={false} position={position} variant={variant} data-sentry-element="RegularLinkButton" data-sentry-component="LoginButton" data-sentry-source-file="LoginButton.component.tsx">
      {children}
    </RegularLinkButton>;
}