import clsx from "clsx";
import { usePathname } from "next/navigation";
import type { Dispatch, SetStateAction } from "react";
import { Imprint } from "@/components/Imprint/Imprint.component";
import { Logo } from "@/components/Logo/Logo.component";
import { headerTransitionClassNames } from "../../common-transition-class-names";
import type { HeaderModel } from "../../models/header-model.types";
import { Navigation } from "../Navigation/Navigation.component";
import { ToolbarMenuDense } from "../ToolbarMenuDense/ToolbarMenuDense.component";
import { UserLabel } from "../UserLabel/UserLabel.component";
type MastheadProps = {
  readonly model: HeaderModel;
  readonly isNavOpen: boolean;
  readonly isCollapsed: boolean;
  readonly setIsNavOpen: Dispatch<SetStateAction<boolean>>;
  readonly className?: string;
  readonly theme?: "primary" | "white";
};
export function Masthead({
  model,
  isNavOpen = false,
  setIsNavOpen,
  className,
  isCollapsed = false,
  theme = "white"
}: MastheadProps) {
  const pathname = usePathname();
  return <div className={clsx("flex w-full transform-gpu items-center justify-center ease-out lg:h-27 print:h-auto", headerTransitionClassNames, isCollapsed ? "h-6" : "h-9", theme === "white" && "border-b border-b-silver bg-white text-charcoal lg:border-none print:border-0", theme === "primary" && "border-none bg-primary text-white print:mb-2 print:bg-transparent print:text-charcoal", className)} data-sentry-component="Masthead" data-sentry-source-file="Masthead.component.tsx">
      <div className="flex h-full w-full items-center justify-between gap-2 max-lg:container lg:w-full lg:flex-col lg:gap-0">
        <div className="relative flex w-full grow items-center justify-center">
          <div className="w-full lg:container">
            <div className="flex w-full items-end justify-between gap-x-2">
              <div>
                {model.brand !== null ? <Imprint brand={model.brand} className="mb-2" /> : null}

                <div className="flex items-end gap-1 print:mb-2">
                  <Logo className="h-3 lg:h-6 xl:h-7 print:h-5" primaryClassName={theme === "primary" ? "fill-white print:fill-site-logo-primary" : "fill-site-logo-primary"} data-sentry-element="Logo" data-sentry-source-file="Masthead.component.tsx" />
                </div>
              </div>

              <UserLabel className="ml-2 hidden select-none text-sm uppercase leading-none text-inherit lg:block" data-sentry-element="UserLabel" data-sentry-source-file="Masthead.component.tsx" />
            </div>
          </div>
        </div>

        {/* Mobile navigation */}
        <ToolbarMenuDense className="lg:hidden print:hidden" hasLatestNews={model.hasLatestNews} isNavOpen={isNavOpen} theme={theme === "primary" ? "light" : "dark"} onToggle={setIsNavOpen} data-sentry-element="ToolbarMenuDense" data-sentry-source-file="Masthead.component.tsx" />

        {/* Desktop navigation */}
        <Navigation className={clsx("hidden h-9 w-full transform-gpu lg:flex print:hidden", headerTransitionClassNames, theme === "primary" && "border-t border-t-white/30")} menuItems={model.menuItems} pathname={pathname} theme={theme === "primary" ? "white" : "primary"} data-sentry-element="Navigation" data-sentry-source-file="Masthead.component.tsx" />
      </div>
    </div>;
}