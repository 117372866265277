import { clsx } from "clsx";
import { Link } from "@/components/Link/Link.component";
import { regularButtonCommonClasses, regularButtonVariantClasses } from "../regular-button-class-names";
import type { CommonButtonProps } from "../regular-button.types";
type LinkButtonProps = CommonButtonProps & {
  readonly openInNewTab?: boolean | "determineFromSource";
  readonly href: string;
  readonly isDisabled?: boolean;
};
export function RegularLinkButton({
  className,
  href,
  openInNewTab,
  disabled = false,
  children,
  position,
  variant = "primary"
}: LinkButtonProps) {
  return <Link className={clsx(className, regularButtonCommonClasses, regularButtonVariantClasses[variant])} disabled={disabled} href={href} openInNewTab={openInNewTab} position={position} data-sentry-element="Link" data-sentry-component="RegularLinkButton" data-sentry-source-file="RegularLinkButton.component.tsx">
      {children}
    </Link>;
}