import type { RegularButtonVariants } from "./regular-button.types";

export const regularButtonCommonClasses =
  "base-button base-hover-button border";

export const regularButtonVariantClasses: Record<
  RegularButtonVariants,
  string
> = {
  accent:
    "border-accent bg-accent hover:border-accent-hover hover:bg-accent-hover text-white",
  outline: "border-silver bg-white text-black hover:border-dim",
  "outline-inverse":
    "border-white bg-transparent text-white hover:text-primary hover:border-white hover:bg-white text-white",
  primary:
    "border-primary bg-primary hover:border-primary-hover hover:bg-primary-hover text-white",
  secondary:
    "border-secondary bg-secondary hover:border-secondary-hover hover:bg-secondary-hover text-white",
};
